import React from "react";
import { VictoryLabel, VictoryPie } from "victory";
import { currency } from "../../lib/common";
import styles from "./PieChart.module.css";

const PieChart = ({ data }) => {
  const filteredData = data.filter((item) => item.y > 0);

  const totalAmount = filteredData.reduce((accumulator, value) => {
    return accumulator + value.y;
  }, 0);

  const getLabel = (datum, totalAmount) => {
    return [
      datum.x,
      `${
        (datum.y * 100) / totalAmount > 100 || (datum.y * 100) / totalAmount < 0
          ? 100
          : Math.round((datum.y / totalAmount) * 10000) / 100
      }%`,
    ];
  };

  return (
    <section className={styles.container}>
      <VictoryPie
        data={filteredData}
        animate={{
          duration: 200,
        }}
        style={{
          labels: {
            fill: "#333",
            fontFamily: "inherit",
            fontSize: "14",
          },
          data: {
            fill: ({ datum }) => datum.color,
          },
        }}
        height={320}
        padding={{ top: 0, right: 107, bottom: 0, left: 107 }}
        labelComponent={<VictoryLabel lineHeight={1.2} />}
        labels={({ datum }) => getLabel(datum, totalAmount)}
        innerRadius={50}
        labelRadius={105}
        events={[
          {
            target: "data",
            eventHandlers: {
              onMouseOver: () => {
                return [
                  {
                    target: "data",
                    mutation: () => ({ style: { fill: "#ccc" } }),
                  },
                  {
                    target: "labels",
                    mutation: () => ({
                      active: true,
                      text: ({ datum }) => currency.format(datum.y),
                      backgroundStyle: {
                        fill: "#fff",
                        opacity: 0.9,
                      },
                      backgroundPadding: 10,
                      style: {
                        fill: "#333",
                        fontFamily: "inherit",
                        fontSize: 16,
                      },
                    }),
                  },
                ];
              },
              onMouseOut: () => {
                return [
                  {
                    target: "data",
                    mutation: () => {},
                  },
                  { target: "labels", mutation: () => ({ active: false }) },
                ];
              },
            },
          },
        ]}
      />
    </section>
  );
};

export default PieChart;
