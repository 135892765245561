import React from "react";
import styles from "./BaseInputs.module.css";
import InfoButton from "./Blocks/InfoButton";
import Slider from "./Blocks/Slider";
import { currency } from "../lib/common";

/**
 * Container for the main inputs controlling the calculations
 */

const BaseInputs = ({
  lonnsintekt_andre_arb_giv,
  overskudd_i_naering,
  handleChangeLonnsintekt,
  handleChangeOverskudd,
  displayModal,
}) => {
  return (
    <section className={styles.container}>
      <Slider
        value={overskudd_i_naering}
        handleChange={(input) => handleChangeOverskudd(input)}
      />

      <section className={styles.value__container}>
        <span>
          Overskudd i næring
          <InfoButton
            infoText={
              "Tilsvarer 'overskudd i næring (uten forsikringer og regnskapskostnader)' i ENK, eller 'resultat før lønnskostnader, forsikringer og regnskap' i eget AS"
            }
            displayModal={displayModal}
          />
        </span>
        <span className={styles.amount}>
          {currency.format(overskudd_i_naering)}
        </span>
      </section>

      <label
        htmlFor="lonnsintekt_andre_arb_giv"
        className={styles.value__container}
      >
        <span>Lønnsinntekt fra andre arbeidsgivere</span>
        <input
          type="number"
          id="lonnsintekt_andre_arb_giv"
          min="0"
          value={lonnsintekt_andre_arb_giv.toString().replace(/^0+/, "0")}
          onChange={(e) => {
            handleChangeLonnsintekt(parseInt(+e.target.value));
          }}
          inputMode="numeric"
        />
      </label>
    </section>
  );
};

export default BaseInputs;
