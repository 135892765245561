import React from "react";
import styles from "./ShowDetailsButton.module.css";

const ShowDetailsButton = ({ isDetailViewOpen, handleClick }) => {
  return (
    <button
      className={`${styles.button} ${isDetailViewOpen && styles.active}`}
      onClick={() => handleClick((prev) => !prev)}
      title="Vis detaljer"
    >
      <span>{isDetailViewOpen ? "Skjul detaljer «" : "Vis detaljer »"}</span>
    </button>
  );
};

export default ShowDetailsButton;
