import { useState } from "react";
import styles from "./App.module.css";
import Enk from "./components/Enk";
import As from "./components/As";
import Employ from "./components/Employ";
import BaseInputs from "./components/BaseInputs";
import Modal from "./components/Blocks/Modal";

function App() {
  const [overskudd_i_naering, setOverskudd_i_naering] = useState(500000);
  const [lonnsintekt_andre_arb_giv, setLonnsintekt_andre_arb_giv] = useState(0);
  const [employTotal, setEmployTotal] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalText, setModalText] = useState("");

  const maanedspris = 1500;
  const antall_aktive_maaneder = 12;
  const overskudd_etter_maanedspris =
    overskudd_i_naering - maanedspris * antall_aktive_maaneder;

  function handleDisplayModal(text) {
    setModalText(text);
    setIsModalOpen(true);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  return (
    <article className={styles.container}>
      <BaseInputs
        overskudd_i_naering={overskudd_i_naering}
        lonnsintekt_andre_arb_giv={lonnsintekt_andre_arb_giv}
        handleChangeOverskudd={setOverskudd_i_naering}
        handleChangeLonnsintekt={setLonnsintekt_andre_arb_giv}
        displayModal={handleDisplayModal}
      />

      <Employ
        overskudd_i_naering={overskudd_i_naering}
        lonnsintekt_andre_arb_giv={lonnsintekt_andre_arb_giv}
        overskudd_etter_maanedspris={overskudd_etter_maanedspris}
        handleTotalAmountChange={(value) => setEmployTotal(value)}
        className={styles.calculations}
        displayModal={handleDisplayModal}
      />

      <Enk
        overskudd_i_naering={overskudd_i_naering}
        lonnsintekt_andre_arb_giv={lonnsintekt_andre_arb_giv}
        employTotal={employTotal}
        className={styles.calculations}
        displayModal={handleDisplayModal}
      />

      <As
        overskudd_i_naering={overskudd_i_naering}
        lonnsintekt_andre_arb_giv={lonnsintekt_andre_arb_giv}
        employTotal={employTotal}
        className={styles.calculations}
        displayModal={handleDisplayModal}
      />

      {isModalOpen && <Modal text={modalText} closeModal={handleCloseModal} />}
    </article>
  );
}

export default App;
