import React from "react";
import styles from "./Modal.module.css";

const Modal = ({ text, closeModal }) => {
  return (
    <>
      <section className={styles.modal__container}>
        <div className={styles.modal__background} onClick={closeModal}></div>
        <div className={styles.modal__box}>
          <p>{text}</p>

          <button
            className={styles.close__button}
            title="Lukk vindu"
            onClick={closeModal}
          >
            X
          </button>
        </div>
      </section>
    </>
  );
};

export default Modal;
