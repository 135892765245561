import React, { useState, useEffect } from "react";
import styles from "./Employ.module.css";
import satser from "../lib/satser_2021";
import Big from "big.js";
import AmountRow from "./Blocks/AmountRow";
import ShowDetailsButton from "./Blocks/ShowDetailsButton";
import PieChart from "./Charts/PieChart";
import { chartColors } from "../lib/common";
import InfoButton from "./Blocks/InfoButton";

const Employ = ({
  overskudd_i_naering,
  lonnsintekt_andre_arb_giv,
  overskudd_etter_maanedspris,
  handleTotalAmountChange,
  className,
  displayModal,
}) => {
  const [isDetailViewOpen, setIsDetailViewOpen] = useState(false);

  useEffect(() => {
    handleTotalAmountChange(netto);
  });

  //
  // CALCULATIONS
  //

  const bruttolonn_under_bonus =
    overskudd_etter_maanedspris >
    satser.bruttolonn.under_resultatbonus_foer_lonn
      ? +Big(satser.bruttolonn.under_resultatbonus_foer_lonn).times(
          satser.faktor.lonn_inkl_fp_og_bonus_under
        )
      : +Big(overskudd_etter_maanedspris).times(
          satser.faktor.lonn_inkl_fp_og_bonus_under
        );

  const bruttolonn_under_bonus_pensjon = +Big(bruttolonn_under_bonus).times(
    satser.pensjon.lav_sats
  );

  const bruttolonn_mellom_bonus_7_1G =
    overskudd_etter_maanedspris > satser.bruttolonn.G7_1_foer_lonn
      ? +Big(satser.bruttolonn.G7_1).minus(bruttolonn_under_bonus)
      : overskudd_etter_maanedspris <
        satser.bruttolonn.under_resultatbonus_foer_lonn
      ? 0
      : +Big(
          Big(overskudd_etter_maanedspris).minus(
            satser.bruttolonn.under_resultatbonus_foer_lonn
          )
        ).times(satser.faktor.lonn_inkl_fp_under_7_1G);

  const bruttolonn_mellom_bonus_7_1G_pensjon = +Big(
    bruttolonn_mellom_bonus_7_1G
  ).times(satser.pensjon.lav_sats);

  const bruttolonn_mellom_7_1G_12G =
    overskudd_etter_maanedspris > satser.bruttolonn.G12_foer_lonn
      ? +Big(satser.bruttolonn.G12)
          .minus(bruttolonn_mellom_bonus_7_1G)
          .minus(bruttolonn_under_bonus)
      : overskudd_etter_maanedspris < satser.bruttolonn.G7_1_foer_lonn
      ? 0
      : +Big(
          Big(overskudd_etter_maanedspris).minus(
            satser.bruttolonn.G7_1_foer_lonn
          )
        ).times(satser.faktor.lonn_inkl_fp_fra_7_1G_til_12G);

  const bruttolonn_mellom_7_1G_12G_pensjon = +Big(
    bruttolonn_mellom_7_1G_12G
  ).times(satser.pensjon.hoy_sats);

  const bruttolonn_over_12G =
    overskudd_etter_maanedspris < satser.bruttolonn.G12_foer_lonn
      ? 0
      : +Big(
          Big(overskudd_etter_maanedspris).minus(
            satser.bruttolonn.G12_foer_lonn
          )
        ).times(satser.faktor.lonn_inkl_fp_over_12G);

  const bruttolonn_sum = +Big(bruttolonn_under_bonus)
    .plus(bruttolonn_mellom_bonus_7_1G)
    .plus(bruttolonn_mellom_7_1G_12G)
    .plus(bruttolonn_over_12G);

  const lonnsinntekt_employ = bruttolonn_sum;

  const sum_inntekt = +Big(lonnsinntekt_employ).plus(lonnsintekt_andre_arb_giv);

  const pensjon_sum = +Big(bruttolonn_under_bonus_pensjon)
    .plus(bruttolonn_mellom_bonus_7_1G_pensjon)
    .plus(bruttolonn_mellom_7_1G_12G_pensjon);

  const grunnlag_AGA = +Big(pensjon_sum).plus(lonnsinntekt_employ);

  const minstefradrag_helper = +Big(sum_inntekt).times(
    satser.minstefradrag_lonn.sats
  );

  const minstefradrag =
    minstefradrag_helper <
    satser.minstefradrag_lonn.saerskilt_fradrag_arbeidsinntekt
      ? sum_inntekt
      : minstefradrag_helper > satser.minstefradrag_lonn.ovre_grense
      ? satser.minstefradrag_lonn.ovre_grense
      : minstefradrag_helper;

  const grunnlag_skatt = +Big(sum_inntekt).minus(minstefradrag);

  const personfradrag =
    +Big(sum_inntekt).minus(minstefradrag_helper) <
    satser.personfradrag.klasse_1
      ? 0
      : satser.personfradrag.klasse_1;

  const alminnelig_inntekt = +Big(sum_inntekt)
    .minus(minstefradrag)
    .minus(personfradrag);

  const trygdeavgift_grunnlag =
    +Big(sum_inntekt).minus(satser.trygdeavgift.nedre_grense_for_trygdeavgift) <
    0
      ? 0
      : +Big(sum_inntekt).minus(
          satser.trygdeavgift.nedre_grense_for_trygdeavgift
        );

  const trygdeavgift_25p_av_belop_over_nedre_grense = +Big(
    trygdeavgift_grunnlag
  ).times(satser.trygdeavgift.maks_av_grunnlaget);

  const trygdeavgift_beregning =
    sum_inntekt > satser.trygdeavgift.nedre_grense_for_trygdeavgift
      ? +Big(sum_inntekt).times(satser.trygdeavgift.lonnsinntekt)
      : 0;

  const trygdeavgift_lonn =
    trygdeavgift_beregning > trygdeavgift_25p_av_belop_over_nedre_grense
      ? trygdeavgift_25p_av_belop_over_nedre_grense
      : trygdeavgift_beregning;

  const trygdeavgift_naering = 0;

  const skatt_alminnelig_inntekt =
    personfradrag < satser.personfradrag.klasse_1
      ? 0
      : +Big(satser.skatt_alminnelig_inntekt).times(alminnelig_inntekt);

  const trinnskatt_over_innslagspunkt = (trinn) =>
    +Big(sum_inntekt).minus(satser.trinnskatt[`trinn_${trinn}`].innslag);

  const trinnskatt_lonn_innenfor_trinn = (trinn) =>
    trinnskatt_over_innslagspunkt(trinn) >
    satser.trinnskatt[`trinn_${trinn}`].storrelse
      ? satser.trinnskatt[`trinn_${trinn}`].storrelse
      : trinnskatt_over_innslagspunkt(trinn);

  const trinnskatt_beregning = (trinn) =>
    +Big(trinnskatt_lonn_innenfor_trinn(trinn)).times(
      satser.trinnskatt[`trinn_${trinn}`].sats
    );

  const trinnskatt_for_trinn = (trinn) =>
    trinnskatt_beregning(trinn) >= 1 ? trinnskatt_beregning(trinn) : 0;

  const trinnskatt_sum = +Big(trinnskatt_for_trinn(1))
    .plus(trinnskatt_for_trinn(2))
    .plus(trinnskatt_for_trinn(3))
    .plus(trinnskatt_for_trinn(4));

  const pensjon_inkl_est_skatt_20 = +Big(pensjon_sum).times(
    Big(1).minus(satser.pensjon.skatt_uttak)
  );

  const skatt_lonnskostnader_AGA = +Big(grunnlag_AGA).times(0.141);

  const inntektsskatt_beregning = +Big(trygdeavgift_lonn)
    .plus(trygdeavgift_naering)
    .plus(skatt_alminnelig_inntekt)
    .plus(trinnskatt_sum);

  const inntektsskatt_sats = +Big(inntektsskatt_beregning).div(
    sum_inntekt !== 0 ? sum_inntekt : 1
  );

  const inntektsskatt =
    inntektsskatt_beregning > 0 ? inntektsskatt_beregning : 0;

  const sum_skatt_inkl_AGA = +Big(inntektsskatt).plus(skatt_lonnskostnader_AGA);

  const netto = +Big(sum_inntekt)
    .minus(inntektsskatt_beregning)
    .plus(pensjon_sum);

  const aga_kostnader = +Big(
    Big(bruttolonn_sum).plus(pensjon_inkl_est_skatt_20)
  ).times(0.141);

  const admin_kostnader = +Big(overskudd_i_naering)
    .plus(lonnsintekt_andre_arb_giv)
    .minus(netto)
    .minus(inntektsskatt_beregning)
    .minus(aga_kostnader);

  //
  // RENDER
  //

  return (
    <section className={`${styles.container} ${className || ""}`}>
      <h3 className={styles.header}>EMPLOY</h3>

      <AmountRow
        name={`Dine penger`}
        amount={netto}
        className={styles.bottomMargin}
        isFinalAmount
      >
        <InfoButton
          infoText={
            "Sum nettolønn pluss brutto pensjonssparing (pensjonen beskattes ved uttak)"
          }
          displayModal={displayModal}
        />
      </AmountRow>

      {/* SHOW DETAILS */}

      <ShowDetailsButton
        isDetailViewOpen={isDetailViewOpen}
        handleClick={setIsDetailViewOpen}
      />

      {isDetailViewOpen && (
        <section className={styles.details__container}>
          <h4 className={styles.details__header}>Driftskostnader</h4>

          <AmountRow name={`Forsikringer`} amount={`inkludert`}>
            <InfoButton
              infoText={"Reise, yrkesskade, behandling, fritidsulykke, liv"}
              displayModal={displayModal}
            />
          </AmountRow>
          <AmountRow name={`Administrasjon og regnskap`} amount={`inkludert`} />
          <AmountRow
            name={`Sykelønn (100% fra første dag)`}
            amount={`inkludert`}
          />

          <hr />

          <AmountRow
            name={`Lønnsinntekt fra andre arbeidsgivere`}
            amount={lonnsintekt_andre_arb_giv}
          />
          <AmountRow
            name={`Lønnsinntekt - Employ`}
            amount={lonnsinntekt_employ}
          />
          <AmountRow name={`Sum inntekt`} amount={sum_inntekt} />
          <AmountRow name={`Minstefradrag`} amount={minstefradrag} />
          <AmountRow name={`Grunnlag skatt`} amount={grunnlag_skatt} />
          <AmountRow name={`Sum personinntekt`} amount={alminnelig_inntekt} />

          <hr />

          <AmountRow name={`Trygdeavgift lønn`} amount={trygdeavgift_lonn} />
          <AmountRow
            name={`Trygdeavgift næring`}
            amount={trygdeavgift_naering}
          />
          <AmountRow
            name={`Skatt på alminnelig inntekt`}
            amount={skatt_alminnelig_inntekt}
          />
          <AmountRow name={`Trinnskatt`} amount={trinnskatt_sum} />
          <AmountRow
            name={`Skatt på lønnskostnader (AGA)`}
            amount={skatt_lonnskostnader_AGA}
          />
          <AmountRow
            name={`Inntektsskatt (${
              Math.round(inntektsskatt_sats * 100) + "%"
            })`}
            amount={inntektsskatt_beregning}
          />
          <AmountRow name={`Sum skatt inkl AGA`} amount={sum_skatt_inkl_AGA} />
          <AmountRow name={`Pensjonssparing`} amount={pensjon_sum}>
            <InfoButton
              infoText={"3,4 % fra 0 - 7,1 G og 21,5 % fra 7,1 - 12 G"}
              displayModal={displayModal}
            />
          </AmountRow>
        </section>
      )}

      {/* CHART */}

      <PieChart
        data={[
          { x: "Dine penger", y: netto, color: chartColors.blue },
          { x: "Skatt", y: inntektsskatt, color: chartColors.yellow },
          { x: "AGA", y: aga_kostnader, color: chartColors.purple },
          {
            x: "Driftskostnader",
            y: admin_kostnader,
            color: chartColors.green,
          },
        ]}
      />
    </section>
  );
};

export default Employ;
