import React from "react";
import { InfoIcon } from "./Icons";
import styles from "./InfoButton.module.css";

const InfoButton = ({ displayModal, infoText }) => {
  return (
    <>
      <button
        className={styles.button}
        title="vis mer informasjon"
        onClick={() => displayModal(infoText)}
      >
        <InfoIcon />
      </button>
    </>
  );
};

export default InfoButton;
