import React from "react";
import styles from "./AmountRow.module.css";
import { currency } from "../../lib/common";

/**
 * This is a component used to display a simple text row consisting of a text label and an amount.
 * It can display an additional checkbox next to the amount used to disable/enable the amount.
 * This is useful for including/excluding values from the calculations.
 * It also accepts children that display next to name.
 * This is currently used to pass in info buttons.
 **/

const AmountRow = ({
  name,
  amount,
  isFinalAmount,
  className,
  children,
  withCheckbox,
  onCheckboxClick,
  checkBoxState,
  checkBoxLabel,
}) => {
  return (
    <p
      className={`${styles.container} ${
        isFinalAmount ? styles.isFinalAmount : ""
      } ${withCheckbox && checkBoxState === false ? styles.disabled : ""} ${
        className ? className : ""
      }`}
    >
      {name && (
        <span>
          {name}
          {children}
        </span>
      )}

      <span className={styles.amount__container}>
        <span className={`${styles.amount}`}>
          {typeof amount === "number" ? currency.format(amount) : amount}
        </span>

        {withCheckbox && (
          <input
            type="checkbox"
            checked={checkBoxState}
            onChange={onCheckboxClick}
            title={checkBoxLabel}
            className={styles.checkBox}
          />
        )}
      </span>
    </p>
  );
};

export default AmountRow;
