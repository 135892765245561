export const currency = new Intl.NumberFormat("no", {
  style: "currency",
  currency: "NOK",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const chartColors = {
  blue: "#009ade",
  yellow: "#f2c349",
  green: "#3fbb9b",
  purple: "#b1a3bf",
};
