import React, { useState } from "react";
import styles from "./Enk.module.css";
import satser from "../lib/satser_2021";
import Big from "big.js";
import AmountRow from "./Blocks/AmountRow";
import ShowDetailsButton from "./Blocks/ShowDetailsButton";
import PieChart from "./Charts/PieChart";
import { chartColors } from "../lib/common";
import InfoButton from "./Blocks/InfoButton";

const Enk = ({
  overskudd_i_naering,
  lonnsintekt_andre_arb_giv,
  employTotal,
  className,
  displayModal,
}) => {
  const [forsikring, setForsikring] = useState(-15000);
  const [adm_regnskap, setAdm_regnskap] = useState(-15000);

  const [isForsikringEnabled, setIsForsikringEnabled] = useState(true);
  const [isAdm_RegnskapEnabled, setIsAdm_RegnskapEnabled] = useState(true);
  const [isSykelonnEnabled, setIsSykelonnEnabled] = useState(true);
  const [isDetailViewOpen, setIsDetailViewOpen] = useState(false);

  //
  // CALCULATIONS
  //

  const overskudd_i_naering_etter_kostnader = +Big(overskudd_i_naering)
    .plus(isForsikringEnabled ? +forsikring : 0)
    .plus(isAdm_RegnskapEnabled ? +adm_regnskap : 0);

  const sykelonn =
    +Big(overskudd_i_naering_etter_kostnader).times(
      satser.tillegstrygd_sykepenger_NAV
    ) > +Big(satser.bruttolonn.G6).times(satser.tillegstrygd_sykepenger_NAV)
      ? +Big(satser.bruttolonn.G6).times(
          +Big(0).minus(satser.tillegstrygd_sykepenger_NAV)
        )
      : +Big(overskudd_i_naering_etter_kostnader).times(
          +Big(0).minus(satser.tillegstrygd_sykepenger_NAV)
        );

  const overskudd_for_pensjon = +Big(overskudd_i_naering)
    .plus(isForsikringEnabled ? +forsikring : 0)
    .plus(isAdm_RegnskapEnabled ? +adm_regnskap : 0)
    .plus(isSykelonnEnabled ? sykelonn : 0);

  const lonnsintekt = lonnsintekt_andre_arb_giv;

  const pensjon_grunnlag =
    overskudd_for_pensjon > satser.bruttolonn.G12
      ? +Big(satser.bruttolonn.G12).minus(satser.grunnbelop_i_folketryden)
      : overskudd_for_pensjon < satser.grunnbelop_i_folketryden
      ? 0
      : +Big(overskudd_for_pensjon).minus(satser.grunnbelop_i_folketryden);

  const pensjon_sum = +Big(pensjon_grunnlag).times(
    satser.pensjon.skattesats_ENK
  );

  const naeringsinntekt = +Big(overskudd_i_naering)
    .plus(isForsikringEnabled ? +forsikring : 0)
    .plus(isAdm_RegnskapEnabled ? +adm_regnskap : 0)
    .plus(isSykelonnEnabled ? sykelonn : 0)
    .minus(pensjon_sum);

  const sum_inntekt = +Big(lonnsintekt).plus(naeringsinntekt);

  const alminnelig_inntekt = +Big(lonnsintekt).plus(naeringsinntekt);

  const minstefradrag_helper = +Big(lonnsintekt).times(
    satser.minstefradrag_lonn.sats
  );

  const minstefradrag =
    lonnsintekt < 31800
      ? lonnsintekt
      : minstefradrag_helper < 31800
      ? 31800
      : minstefradrag_helper < satser.minstefradrag_lonn.ovre_grense
      ? minstefradrag_helper
      : satser.minstefradrag_lonn.ovre_grense;

  const grunnlag_skatt = +Big(sum_inntekt).minus(minstefradrag);

  const full_trygdeavgift_lonn = +Big(lonnsintekt).times(
    satser.trygdeavgift.lonnsinntekt
  );

  const full_trygdeavgift_naering = +Big(naeringsinntekt).times(
    satser.trygdeavgift.naeringsinntekt
  );

  const sum_full_trygdeavgift = +Big(full_trygdeavgift_lonn).plus(
    full_trygdeavgift_naering
  );

  const grunnlag_trygdeavgift =
    +Big(sum_inntekt).minus(satser.trygdeavgift.nedre_grense_for_trygdeavgift) <
    0
      ? 0
      : +Big(sum_inntekt).minus(
          satser.trygdeavgift.nedre_grense_for_trygdeavgift
        );

  const trygdeavgift_25p_av_belop_over_nedre_grense = +Big(
    grunnlag_trygdeavgift
  ).times(satser.trygdeavgift.maks_av_grunnlaget);

  const trygdeavgift_beregning =
    naeringsinntekt > satser.trygdeavgift.nedre_grense_for_trygdeavgift
      ? +Big(naeringsinntekt).times(satser.trygdeavgift.naeringsinntekt)
      : 0;

  const trygdeavgift_naering =
    trygdeavgift_beregning > trygdeavgift_25p_av_belop_over_nedre_grense
      ? trygdeavgift_25p_av_belop_over_nedre_grense
      : trygdeavgift_beregning;

  const trygdeavgift_lonn =
    trygdeavgift_25p_av_belop_over_nedre_grense < trygdeavgift_beregning
      ? 0
      : sum_full_trygdeavgift > trygdeavgift_25p_av_belop_over_nedre_grense
      ? +Big(trygdeavgift_25p_av_belop_over_nedre_grense).minus(
          trygdeavgift_naering
        )
      : full_trygdeavgift_lonn;

  const personfradrag =
    +Big(sum_inntekt).minus(minstefradrag_helper) <
    satser.personfradrag.klasse_1
      ? +Big(sum_inntekt).minus(+Big(trygdeavgift_lonn).times(0.44))
      : satser.personfradrag.klasse_1;

  const sum_nettoinntekt = +Big(sum_inntekt)
    .minus(minstefradrag)
    .minus(personfradrag);

  const skatt_alminnelig_inntekt =
    personfradrag >= satser.personfradrag.klasse_1
      ? +Big(satser.skatt_alminnelig_inntekt).times(sum_nettoinntekt)
      : 0;

  const trinnskatt_over_innslagspunkt = (trinn) =>
    +Big(sum_inntekt).minus(satser.trinnskatt[`trinn_${trinn}`].innslag);

  const trinnskatt_lonn_innenfor_trinn = (trinn) =>
    trinnskatt_over_innslagspunkt(trinn) >
    satser.trinnskatt[`trinn_${trinn}`].storrelse
      ? satser.trinnskatt[`trinn_${trinn}`].storrelse
      : trinnskatt_over_innslagspunkt(trinn);

  const trinnskatt_beregning = (trinn) =>
    +Big(trinnskatt_lonn_innenfor_trinn(trinn)).times(
      satser.trinnskatt[`trinn_${trinn}`].sats
    );

  const trinnskatt_for_trinn = (trinn) =>
    trinnskatt_beregning(trinn) >= 1 ? trinnskatt_beregning(trinn) : 0;

  const trinnskatt_sum = +Big(trinnskatt_for_trinn(1))
    .plus(trinnskatt_for_trinn(2))
    .plus(trinnskatt_for_trinn(3))
    .plus(trinnskatt_for_trinn(4));

  const inntektsskatt_beregning = +Big(trygdeavgift_lonn)
    .plus(trygdeavgift_naering)
    .plus(skatt_alminnelig_inntekt)
    .plus(trinnskatt_sum);

  const inntektsskatt =
    inntektsskatt_beregning > 0 ? inntektsskatt_beregning : 0;

  const inntektsskatt_sats = +Big(inntektsskatt_beregning).div(
    alminnelig_inntekt >= 1 ? alminnelig_inntekt : 1
  );

  const netto = +Big(alminnelig_inntekt).minus(inntektsskatt).plus(pensjon_sum);

  const admin_kostnader = +Big(
    Big(isForsikringEnabled ? +forsikring : 0)
      .plus(isAdm_RegnskapEnabled ? +adm_regnskap : 0)
      .plus(isSykelonnEnabled ? sykelonn : 0)
  ).times(-1);

  const differanse_per_ar = +Big(netto).minus(employTotal);
  const differanse_per_maaned = +Big(differanse_per_ar).div(12);

  //
  // RENDER
  //

  return (
    <section className={`${styles.container} ${className || ""}`}>
      <h3 className={styles.header}>ENKELTPERSONFORETAK</h3>

      <AmountRow name={`Dine penger`} amount={netto} isFinalAmount>
        <InfoButton
          infoText={
            "Sum nettolønn pluss brutto pensjonssparing (pensjonen beskattes ved uttak)"
          }
          displayModal={displayModal}
        />
      </AmountRow>
      <AmountRow name={`Differanse per år`} amount={differanse_per_ar} />
      <AmountRow name={`Differanse per måned`} amount={differanse_per_maaned} />

      {/* SHOW DETAILS */}

      <ShowDetailsButton
        isDetailViewOpen={isDetailViewOpen}
        handleClick={setIsDetailViewOpen}
      />

      {isDetailViewOpen && (
        <section className={styles.details__container}>
          <h4>Driftskostnader</h4>

          <section className={styles.input__container}>
            <label htmlFor="forsikring">
              <span>Forsikringer</span>
              <InfoButton
                infoText={"Reise, yrkesskade, behandling, fritidsulykke, liv"}
                displayModal={displayModal}
                aria-hidden="true"
              />
            </label>

            <section>
              <input
                type="number"
                id="forsikring"
                value={forsikring.toString().replace(/^0+/, "0")}
                onChange={(e) => setForsikring(e.target.value)}
                disabled={!isForsikringEnabled}
                max={0}
              />
              <input
                type="checkbox"
                checked={isForsikringEnabled}
                onChange={() => setIsForsikringEnabled((prev) => !prev)}
                title="Kryss av for å inkludere forsikring i beregninger"
              />
            </section>
          </section>

          <section className={styles.input__container}>
            <label htmlFor="adm_regnskap">Administrasjon og regnskap</label>
            <section>
              <input
                type="number"
                id="adm_regnskap"
                value={adm_regnskap.toString().replace(/^0+/, "0")}
                onChange={(e) => setAdm_regnskap(e.target.value)}
                disabled={!isAdm_RegnskapEnabled}
                max={0}
              />
              <input
                type="checkbox"
                checked={isAdm_RegnskapEnabled}
                onChange={() => setIsAdm_RegnskapEnabled((prev) => !prev)}
                title="Kryss av for å inkludere administrasjon og regnskap i beregninger"
              />
            </section>
          </section>

          <AmountRow
            name={`Sykepenger (100% fra første dag)`}
            amount={sykelonn}
            withCheckbox
            checkBoxState={isSykelonnEnabled}
            onCheckboxClick={() => setIsSykelonnEnabled((prev) => !prev)}
            checkBoxLabel="Kryss av for å inkludere sykepenger i beregninger"
          />

          <hr />

          <AmountRow name={`Lønnsinntekt`} amount={lonnsintekt} />
          <AmountRow name={`Næringsinntekt`} amount={naeringsinntekt} />
          <AmountRow name={`Sum inntekt`} amount={sum_inntekt} />
          <AmountRow name={`Minstefradrag`} amount={minstefradrag} />
          <AmountRow name={`Grunnlag skatt`} amount={grunnlag_skatt} />
          <AmountRow name={`Sum personinntekt`} amount={sum_nettoinntekt} />

          <hr />

          <AmountRow name={`Trygdeavgift lønn`} amount={trygdeavgift_lonn} />
          <AmountRow
            name={`Trygdeavgift næring`}
            amount={trygdeavgift_naering}
          />
          <AmountRow
            name={`Skatt på alminnelig inntekt`}
            amount={skatt_alminnelig_inntekt}
          />
          <AmountRow name={`Trinnskatt`} amount={trinnskatt_sum} />

          <br />

          <AmountRow
            name={`Inntektsskatt (${
              Math.round(inntektsskatt_sats * 100) + "%"
            })`}
            amount={inntektsskatt}
          />
          <AmountRow name={`Sum skatt`} amount={inntektsskatt} />
          <AmountRow name={`Pensjonssparing`} amount={pensjon_sum}>
            <InfoButton
              infoText={"4% fra 1 - 12 G"}
              displayModal={displayModal}
            />
          </AmountRow>
        </section>
      )}

      {/* CHART */}

      <PieChart
        data={[
          { x: "Dine penger", y: netto, color: chartColors.blue },
          { x: "Skatt", y: inntektsskatt, color: chartColors.yellow },
          {
            x: "Driftskostnader",
            y: admin_kostnader,
            color: chartColors.green,
          },
        ]}
      />
    </section>
  );
};

export default Enk;
